import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Portal } from '@chakra-ui/react'
import { ModalWrapper } from 'components/ModalWrapper/ModalWrapper'
import { Button } from 'design-system/Button/Button'
import { Font } from 'design-system/Font/Font'
import { useState } from 'react'
import { useTranslate } from 'services/i18n'

export type ConfirmDialogProps = {
  description?: string
  onValidate: () => void | Promise<void>
}

export const ConfirmDialog = () => {
  return (
    <ModalWrapper modalId="confirmDialog">
      {({ hideModal, props }) =>
        props ? (
          <Portal>
            <Modal isOpen onClose={hideModal} size="6xl">
              <ModalOverlay />
              <ModalContent margin={'auto'}>
                <ModalCloseButton backgroundColor={'transparent'} size="lg" />
                <ModalBody padding={'24px'}>
                  <Content hideModal={hideModal} onValidate={props?.onValidate} description={props?.description} />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Portal>
        ) : null
      }
    </ModalWrapper>
  )
}

const Content = ({ onValidate, description, hideModal }: ConfirmDialogProps & { hideModal: () => void }) => {
  const t = useTranslate()
  const [isLaoding, setIsloading] = useState(false)

  const handleValidate = () => {
    const result = onValidate()
    if (result instanceof Promise) {
      setIsloading(true)
      result.finally(() => {
        setIsloading(false)
        hideModal()
      })
    } else {
      onValidate()
      hideModal()
    }
  }

  return (
    <Flex direction={'column'} gap={'12px'}>
      <Font family="regular" size={14}>
        {description}
      </Font>
      <Flex gap={'12px'} justifyContent={'end'}>
        <Button disabled={isLaoding} variant="secondary" onClick={hideModal} title={t('cancel')} />
        <Button isLoading={isLaoding} onClick={handleValidate} title={t('validate')} />
      </Flex>
    </Flex>
  )
}
